import React, { useEffect, useState } from 'react';

import styles from './styles.module.scss';

import data from './../data/index.json';
import GetVoucher from '../coponents/getVoucher';
import PakuwonMallUserServices from '../services/users/pakuwonMall';
import { IUserResourceShortProps } from '../props/vouchers/userVoucher';
import LoadingGif from './../assets/loading.gif';
import EnterCode from '../components/enterCode';
import Roulette from '../components/roulette';
import VoucherClaimed from '../components/voucherClaimed';
import VoucherRedeemed from '../components/voucherRedeemed';
import VoucherExpired from '../components/voucherExpired';
import NoUser from '../components/noUser';

type MainProps = {};


const Main: React.FC<MainProps> = (props) => {
  const [user, setUser] = useState<IUserResourceShortProps>();
  const [loaded, setLoaded] = useState(false);
  const [loadingText, setLoadingText] = useState<string>("Please Wait");
  const [isCodeConfirmed, setIsCodeConfirmed] = useState<boolean>(false);
  const [isExpired, setIsExpired] = useState<boolean>(false);

  const init = async () => {
    setLoaded(false);
    setLoadingText('Preparing Your Gelato');

    const url = new URL(window.location.href);
    const _uid = url.searchParams.get('_id');
    if (_uid) {
      try {
        const expiry = new Date(2022, 9, 1, 0, 0, 0);
        const now = new Date();
        const isExpired = now.getTime() > expiry.getTime();

        const user = await PakuwonMallUserServices.get(_uid);
        setUser(user);
        setLoaded(true);
        setIsExpired(isExpired);
      } catch (error) {
        setLoaded(true);
      }
    } else {
      setLoaded(true);
    }
  }

  useEffect(() => {
    init();
  }, []);

  const _onCodeSubmitted = () => {
    setIsCodeConfirmed(true);
  }

  const _onVoucherSelected = async (voucher: string) => {
    if (user) {
      setLoaded(false);
      setLoadingText("Preparing Your Free Gelato");
      await PakuwonMallUserServices.update(user.id, voucher);
      const newUser = await PakuwonMallUserServices.get(user.id);
      setUser(newUser);
      setLoaded(true);
    }
  }

  const _onVoucherRedeemed = async () => {
    if (user) {
      setLoaded(false);
      setLoadingText("Preparing Your Gelato");
      await PakuwonMallUserServices.redeem(user.id);
      const newUser = await PakuwonMallUserServices.get(user.id);
      setUser(newUser);
      setLoaded(true);
    }
  }

  return (
    <section className={styles.container}>
      {!loaded ? <div className={styles.loading}>
        <div className={'overlay'}></div>
        <div className={styles.content}>
          <img src={LoadingGif} className={styles.loadingGif} />
          <span className={`font-amersn ${styles.text}`}>{loadingText}</span>
        </div>
      </div> : null}
      {loaded && !user ? <NoUser /> : null}
      {user && !user.voucher && !user.claimedOn && !isCodeConfirmed && !isExpired ? <EnterCode user={user} onConfirmed={_onCodeSubmitted} /> : null}
      {user && !user.voucher && !user.claimedOn && isCodeConfirmed && !isExpired ? <Roulette user={user} onCompleted={_onVoucherSelected} /> : null}
      {user && user.voucher && !user.redeemedOn && !isExpired ? <VoucherClaimed onRedeem={_onVoucherRedeemed} user={user} /> : null}
      {user && user.voucher && user.redeemedOn && !isExpired ? <VoucherRedeemed user={user} /> : null}
      {user && isExpired ? <VoucherExpired user={user} /> : null}
      {/*{loaded && userVoucher && !userVoucher.voucher && <GetVoucher userVoucher={userVoucher} />}
      {loaded && userVoucher && userVoucher.voucher && (
        <span> "Hi {userVoucher.name}, please redeem ......"</span>
      )}
      {loaded && !userVoucher && <span>Sorry, looks like your id is invalid</span>}*/}
    </section>
  );

  async function getUser() {
    
  }
};

export default Main;
