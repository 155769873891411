import React, { useEffect, useState } from 'react';

import styles from './styles.module.scss';
import { IUserResourceShortProps } from '../../props/vouchers/userVoucher';

type NoUserProps = {
};

const NoUser: React.FC<NoUserProps> = (props) => {
    return <div className={styles.noUser}>
        <div className={"overlay"}></div>
        <div className={styles.content}>
            <h3 className={`font-loffers ${styles.heading}`}>Ciao!</h3>
            <p>Looks like we could not find you in our system!</p>
        </div>
    </div>;
};

export default NoUser;
