import React, { useEffect } from 'react';
import Main from './pages';
import './App.css';

type AppProps = {};

const App: React.FC<AppProps> = (props) => {
  return <Main />;
};

export default App;
