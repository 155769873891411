const GeneralConfig = {
  homepage: 'https://admin.louregelateria.id',
  apiHostname: 'https://api.louregelateria.id',
  hostname: 'https://admin.louregelateria.id',
  assetsHostname: 'https://api.louregelateria.id',
  dateFormat: 'DD/MM/YYYY',
  googleClientId: '',
  facebookAppId: '',
};

export default GeneralConfig;
