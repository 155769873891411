import React, { useEffect, useState } from 'react';

import styles from './styles.module.scss';
import { IUserResourceShortProps } from '../../props/vouchers/userVoucher';

type EnterCodeProps = {
    user: IUserResourceShortProps
    onConfirmed(): void;
};

const EnterCode: React.FC<EnterCodeProps> = (props) => {
    const [code, setCode] = useState<string>("");
    const [error, setError] = useState<string | undefined>();
    
    const _onSubmit = () => {
        setError(undefined);
        if (props.user.code.trim().toLowerCase() === code.trim().toLowerCase()) {
            props.onConfirmed();
        } else {
            setError("Oops, your code is incorrect. Please try again!");
        }
    }

    const _onCodeChanged = (evt: any) => {
        if (error) {setError(undefined);}
        setCode(evt.target.value);
    }

    return <div className={styles.enterCode}>
        <div className={"overlay"}></div>
        <div className={styles.content}>
            <h3 className={`font-loffers ${styles.heading}`}>Ciao  {props.user.name} !</h3>
            <p className={`font-gotham ${styles.label}`}>Please enter your code</p>
            <input onChange={_onCodeChanged} className={styles.input} type={'text'} />
            {error ? <p className={`font-gotham ${styles.error}`}>{error}</p> : null}
            <button onClick={_onSubmit} className={'primary-button'}>Submit</button>
        </div>
    </div>;
};

export default EnterCode;
