import React, { useEffect, useState } from 'react';

import styles from './styles.module.scss';
import { IUserResourceShortProps } from '../../props/vouchers/userVoucher';

type VoucherExpiredProps = {
    user: IUserResourceShortProps;
};

const VoucherExpired: React.FC<VoucherExpiredProps> = (props) => {
    return <div className={styles.voucherExpired}>
        <div className={"overlay"}></div>
        <div className={styles.content}>
            <h3 className={`font-loffers ${styles.heading}`}>Ciao {props.user.name}!</h3>
            <p>Opps! Unfortunately this voucher is already expired 😫</p>
        </div>
    </div>;
};

export default VoucherExpired;
