import React, { useEffect, useState } from 'react';

import styles from './styles.module.scss';
import { IUserResourceShortProps } from '../../props/vouchers/userVoucher';

type VoucherClaimedProps = {
    user: IUserResourceShortProps;
    onRedeem(): void;
};

const VoucherClaimed: React.FC<VoucherClaimedProps> = (props) => {
    return <div className={styles.voucherClaimed}>
        <div className={"overlay"}></div>
        <div className={styles.content}>
            <h3 className={`font-loffers ${styles.heading}`}>Congratulations!</h3>
            <p>You just got</p>
            <p style={{fontSize: 26, textTransform: 'uppercase', fontWeight: '600'}} className={'font-gotham'}>{props.user.voucher || "-"}</p>
            <p>Please redeem this voucher within this month in our store</p>
            <button onClick={props.onRedeem} className='primary-button'>Redeem</button>
            <p style={{fontSize: 14, marginTop: 5, color: 'red'}}>Please do not click the "Redeem" button until you got your {props.user.voucher}.</p>
        </div>
    </div>;
};

export default VoucherClaimed;
