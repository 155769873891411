import RequestService from '../request';
import { IUserResourceShortProps } from '../../props/vouchers/userVoucher';

const PakuwonMallUserServices = {
  get: async (id: string): Promise<IUserResourceShortProps> => {
    try {
      const response = await RequestService.get(`/api/pakuwon_mall_opening_users/${id}`, true);
      return response;
    } catch (error) {
      throw error;
    }
  },
  update: async (id: string, voucher: string): Promise<IUserResourceShortProps> => {
    const fd = new FormData();
    fd.append('voucher', voucher);
    try {
      const response = await RequestService.post(
        `/api/pakuwon_mall_opening_users/${id}?_method=PUT`,
        fd,
        'multipart/form-data',
        true
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  redeem: async (id: string): Promise<IUserResourceShortProps> => {
    try {
      const response = await RequestService.post(
        `/api/pakuwon_mall_opening_users/${id}/redeem`,
        new FormData(),
        'multipart/form-data',
        true
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
};

export default PakuwonMallUserServices;
