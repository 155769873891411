import React, { useEffect, useState } from 'react';

import styles from './styles.module.scss';
import { IUserResourceShortProps } from '../../props/vouchers/userVoucher';

type VoucherRedeemedProps = {
    user: IUserResourceShortProps;
};

const VoucherRedeemed: React.FC<VoucherRedeemedProps> = (props) => {
    return <div className={styles.voucherRedeemed}>
        <div className={"overlay"}></div>
        <div className={styles.content}>
            <h3 className={`font-loffers ${styles.heading}`}>Ciao {props.user.name}!</h3>
            <p>You've redeemed your voucher! We hope you like it!</p>
            <i><p>speriamo di rivederti presto!</p></i>
        </div>
    </div>;
};

export default VoucherRedeemed;
