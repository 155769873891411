import React, { useEffect, useState } from 'react';

import styles from './styles.module.scss';
import { IUserResourceShortProps } from '../../props/vouchers/userVoucher';
import SlotMachineImage from './../../assets/slot.png';
import LogoSquare from './../../assets/logo-square.png';
import PakuwonMallUserServices from '../../services/users/pakuwonMall';

type RouletteProps = {
    user: IUserResourceShortProps;
    onCompleted(voucher: string): void;
};

const Roulette: React.FC<RouletteProps> = (props) => {
    const vouchers: {
        name: string;
        probability: number;
    }[] = [
        {name: '1 Free Medium Cup', probability: 0.2},
        {name: '1 Free Small Cup', probability: 0.5},
        {name: '1 Free Gelato 250ml', probability: 0.1},
        {name: '1 Free Gelato 350ml', probability: 0.05},
        {name: 'Unlimited Toppings (For dine in)', probability: 0.15}
    ];
    const randomiseHelper: string[] = [];
    vouchers.forEach((voucher) => {
        const prob = voucher.probability * 100;
        for (var x = 0; x < prob; x++) {
            randomiseHelper.push(voucher.name);
        }
    });

    const [randomiseInterval, setRandomiseInterval] = useState<any>();

    const [isStart, setIsStart] = useState<boolean>(false);

    const _onStartRoulette = () => {
        setIsStart(true);
        setRandomiseInterval(setInterval(() => {
            const availableVouchers = randomiseHelper.filter((v) => v.toLowerCase() !== selectedVoucher.toLowerCase());
            const randomNumber = Math.floor(Math.random() * availableVouchers.length);
            setSelectedVoucher(availableVouchers[randomNumber]);
        }, 50));
    }

    const _onStopRouelette = async () => {
        clearInterval(randomiseInterval);
        setRandomiseInterval(undefined);
        setIsStart(false);
        props.onCompleted(selectedVoucher);
    }

    const [selectedVoucher, setSelectedVoucher] = useState<string>("");

    return <div className={styles.roulette}>
        <h3 className={`font-loffers ${styles.heading}`}>Ciao  {props.user.name} !</h3>
        <p>We're gladly invite you to join LOURE GELATERIA soft opening!</p>
        <p>We'll bring you to little italy where you can enjoy as much gelato as you want and create your favorite flavor combo. </p>
        <i><p>ci vediamo lì</p></i>
        <p>here's a little reward for you for solving our mini quiz! please redeem this voucher within this month❤️ </p>
        {!isStart ? <button onClick={_onStartRoulette} className={styles.startButton}>tap to start!</button> : null}
        {isStart ? <button onClick={_onStopRouelette} className={styles.startButton}>tap to stop!</button> : null}
        <div className={styles.slotMachine}>
            <img src={SlotMachineImage} className={styles.slotImage} />
            <span className={styles.randomVoucher}>
                {selectedVoucher === "" ? <img src={LogoSquare} className={styles.loadingLogo} /> : null}
                {selectedVoucher !== "" ? selectedVoucher : null}
            </span>
        </div>
    </div>;
};

export default Roulette;
